import React, {FC, PropsWithChildren, useContext, useEffect, useState} from "react";
import { AuthContext } from "./AuthProvider";
import { useMutation, useLazyQuery, ApolloError } from "@apollo/client";
import {
    GET_SETTINGS_LANGUAGE_QUERY,
    SET_SETTINGS_LANGUAGE,
    CREATE_SETTINGS_LANGUAGE,
    GET_LANGUAGE_OPTIONS
} from "components/pages/settings/SettingsGql";
import useLocalStorage from "../utils/LocalStorage";

export const SettingsContext = React.createContext({
    gqlLoading: false,
    gqlError: undefined as ApolloError | undefined,
    language: "",
    rowsPerPage: 25,
    availableLanguages: [],
    setLanguage: (lang: string) => { },
    setRowsPerPage: (num: number) => { },
    allLoaded: false,
    smallMode: false,
    setSmallMode: (x: boolean) => { }
});

export const SettingsProvider: FC<PropsWithChildren> = ({children}) => {
    const auth = useContext(AuthContext);

    const [getSettingsLanguage, { loading: settingsLoading, error: settingsError, data: settingsData, refetch: settingsRefetch }] = useLazyQuery(GET_SETTINGS_LANGUAGE_QUERY);
    const [getLanguageOptions, { loading: languageLoading, error: languageError, data: languageData }] = useLazyQuery(GET_LANGUAGE_OPTIONS);
    const [setSettingsLanguage] = useMutation(SET_SETTINGS_LANGUAGE);
    const [createSettingsLanguage] = useMutation(CREATE_SETTINGS_LANGUAGE);

    const localLanguage = localStorage.getItem("languageContextObject");
    const [language, setLanguage] = useState(!localLanguage ? "" : localLanguage);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [gqlError, setGqlError] = useState<ApolloError | undefined>(undefined);
    const [gqlLoading, setGqlLoading] = useState(false);
    const [availableLanguages, setAvailableLanguages] = useState<any>([]);
    const [smallMode, setSmallMode] = useLocalStorage<boolean>('smallMode', false);

    useEffect(() => {
        if (auth.isAuthenticated()) {
            getSettingsLanguage();
            getLanguageOptions();
        }
    }, [getSettingsLanguage, getLanguageOptions, auth]);

    useEffect(() => {
        if (!settingsLoading && !settingsError && settingsData && languageData) {
            setGqlLoading(false);
            setGqlError(undefined);
            setLangaugeMenuOptions();
            if (settingsData.mySettings?.displayLanguage == null) {
                setLanguageFromBrowser()
            } else {
                setLanguage(settingsData.mySettings.displayLanguage)
            }
        }

        if (settingsError) {
            setLanguage(window.navigator.language);
        }

        setGqlLoading(settingsLoading || languageLoading);
        setGqlError(settingsError || languageError);
        // eslint-disable-next-line
    }, [settingsLoading, settingsError, settingsData, settingsRefetch, languageLoading, languageError, languageData]);

    const setLangaugeMenuOptions = () => {
        let languagesArray: any[] = [];

        languageData.languages.forEach((element) => {
            languagesArray.push([{ "name": element.name, "nativeName": element.nativeName }]);
        });
        setAvailableLanguages(languagesArray);
    };

    const setLanguageFromBrowser = () => {
        let knownLanguage = false;

        for (let i = 0; i < languageData.languages.length; i++) {
            if (window.navigator.language === languageData.languages[i].name) {
                setLanguage(window.navigator.language);
                createLanguageSetting(window.navigator.language);
                knownLanguage = true;
                break;
            }
        }
        if (!knownLanguage) {
            createLanguageSetting(!!localLanguage ? localLanguage : "en-GB");
        }
    };

    // GQL Methods //

    const createLanguageSetting = (lang: string) => {
        createSettingsLanguage(
            {
                variables: {
                    settings: {
                        displayLanguage: lang
                    }
                }
            }
        ).then(settingsRefetch);
    };

    const languageCallback = (lang: string) => {
        setGqlLoading(true);
        setSettingsLanguage(
            {
                variables: {
                    settings: {
                        displayLanguage: lang
                    }
                }
            }
        ).then(settingsRefetch); //update gql query data
    };

    const rowNumberChangeCallback = (num: number) => {
        setRowsPerPage(num);
    }

    const contextObject = {
        gqlLoading: gqlLoading,
        gqlError: gqlError,
        language: language,
        rowsPerPage: rowsPerPage,
        availableLanguages: availableLanguages,
        setLanguage: languageCallback,
        setRowsPerPage: rowNumberChangeCallback,
        allLoaded: false,
        smallMode: smallMode,
        setSmallMode: setSmallMode
    };

    const settingsLoaded = (obj) => {
        return !!obj.language && !!obj.rowsPerPage && !!obj.availableLanguages
    }

    contextObject.allLoaded = settingsLoaded(contextObject);

    return <SettingsContext.Provider value={contextObject}>{children}</SettingsContext.Provider>;
};