export enum Permission {
    ViewSystemSettings = 1,
    ViewRestaurants,
    ViewTargets,
    ViewRestaurantsSettings,
    ViewUsers,
    ViewTasks,
    ViewTemplates,
    ViewEditTemplate,
    ViewStations,
    ViewAddUser,
    ViewSchedules,
    ViewAllTemplates,
    ViewBreaks,
    ViewStaff,
    ViewEditStaff,
    ViewShifts,
    ViewEditRoster,
    ViewWorkingLimits,
    viewStaffAvailability,
    AddStaffAvailabilityException,
    ViewFixedPositions,
    AddSkills,
    DeleteSkills,
    ResetPassword,
    ViewMonthlyOvertime,
    ViewAvailabilityReview,
    ViewBatchOptimisation,
    ViewTraining,
    ViewTrainingSetup,
    ViewTimeOff,
    ViewRosteredStaff,
    ViewRosteredStaffMenuItem,
    ViewRosteredStaffLocationSelectorMenuItem,
    ViewRosterTimeWindow,
    ViewDailyDutiesMenuItem,
    ViewDeadlineSetup
}

export const CanAccess = (permission: Permission, role: {}) => {
    let access;
    switch (permission) {
        case Permission.ViewSystemSettings:
        case Permission.ViewUsers:
        case Permission.ViewAddUser:
        case Permission.ViewStations:
        case Permission.ViewTasks:
        case Permission.ViewAllTemplates:
        case Permission.ViewBreaks:
        case Permission.ViewWorkingLimits:
            access = (role === "Admin");
            break;
        case Permission.ViewRestaurants:
        case Permission.AddSkills:
        case Permission.DeleteSkills:
        case Permission.ResetPassword:
        case Permission.ViewTrainingSetup:
        case Permission.ViewRosteredStaffLocationSelectorMenuItem:
        case Permission.ViewDeadlineSetup:
            access = (role === "Admin" || role === "HQ Manager");
            break;
        case Permission.ViewFixedPositions:
            access = (role === "Admin" || role === "Restaurant Manager");
            break;
        case Permission.ViewStaff:
        case Permission.ViewEditStaff:
            access = (role === "HQ Manager" || role === "Restaurant Manager");
            break;
        case Permission.ViewRosteredStaffMenuItem:
            access = (role !== "Admin" && role !== "HQ Manager" && role !== "Staff");
            break;
        case Permission.ViewSchedules:
        case Permission.ViewTemplates:
        case Permission.ViewEditTemplate:
        case Permission.ViewEditRoster:
        case Permission.ViewMonthlyOvertime:
        case Permission.ViewAvailabilityReview:
        case Permission.ViewRosteredStaff:
        case Permission.ViewRosterTimeWindow:
        case Permission.ViewTraining:
        case Permission.ViewTargets:
        case Permission.ViewDailyDutiesMenuItem:
            access = (role !== "Staff");
            break;
        case Permission.ViewBatchOptimisation:
        case Permission.ViewRestaurantsSettings:
            access = (role === "Restaurant Manager" || role === "HQ Manager" || role === "Admin");
            break;
        case Permission.ViewTimeOff:
            access = (role === "Restaurant Manager");
            break;
        case Permission.ViewShifts:
        case Permission.viewStaffAvailability:
            access = (role === "Staff");
            break;
        case Permission.AddStaffAvailabilityException:
            access = (role === "Staff");
            break;
        default:
            access = true;
            break;
    }
    return access;
};