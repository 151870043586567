import React from "react";
import loadable from '@loadable/component'
import { AuthProvider } from "./components/providers/AuthProvider";
import { ApolloProviderWrapper } from "./components/providers/ApolloProviderWrapper";
import { ThemeProviderWrapper } from "./components/providers/ThemeProviderWrapper";
import { SettingsProvider } from "./components/providers/SettingsProvider";
import { GeneralProvider } from "./components/providers/GeneralProvider";
import useLocalStorage from "./components/utils/LocalStorage";
import {QueryClient, QueryClientProvider} from "react-query";
import {RestaurantProvider} from "./components/providers/RestaurantProvider";
import {startAppInsights} from "./components/providers/AppInsights";
import MockDate from 'mockdate'

function getFaviconEl() {
    return document.getElementById("favicon");
}

function App() {
    const params = new URLSearchParams(window.location.search);
    const mockdateString = params.get("mockToday");
    if (mockdateString) {
        MockDate.set(mockdateString);
    }
    const Routes = process.env.REACT_APP_BUILD === 'staff' ? loadable(() => import('./routes/StaffRoutes')) : loadable(() => import('./routes/Routes'));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [removeKuraBranding, setRemoveKuraBranding] = useLocalStorage<boolean>("removeKuraBranding", false);

    if (removeKuraBranding) {
        const favicon = getFaviconEl() as HTMLAnchorElement;
        if (favicon) {
            favicon.href = "https://bigtable.timeshift.work/favicon.ico";
        }
    }

    startAppInsights();
    const queryClient = new QueryClient();

    return (
        <AuthProvider>
            <ApolloProviderWrapper>
                <QueryClientProvider client={queryClient}>
                    <SettingsProvider>
                        <ThemeProviderWrapper>
                            <GeneralProvider>
                                <RestaurantProvider>
                                    <Routes/>
                                </RestaurantProvider>
                            </GeneralProvider>
                        </ThemeProviderWrapper>
                    </SettingsProvider>
                </QueryClientProvider>
            </ApolloProviderWrapper>
        </AuthProvider>
    );
}

export default App;