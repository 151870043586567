import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import blue from '@mui/material/colors/blue';
import {cyan, green, orange, red} from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: blue[500],
        },
        success: {
            main: green[500],
            light: green[300],
            dark: green[700]
        },
        warning: {
            main: orange[500],
            light: orange[300],
            dark: orange[700]
        },
        info: {
            main: cyan[500],
            light: cyan[300],
            dark: cyan[700]
        },
        error: {
            main: red[500],
            light: red[300],
            dark: red[700]
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071rem'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '8px 24px',
                    '&:first-child': {
                        paddingTop: '20px'
                    }
                },
                dividers: {
                    padding: '8px 24px'
                }

            }
        }
    }
});

export const ThemeProviderWrapper = (props) => {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}
