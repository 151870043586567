import React, {useContext} from "react";
import {getStaffName} from "../../staff/StaffUtils";
import {StaffSelectorItem} from "./position/PositionStaffSelector";
import {GeneralContext} from "../../../providers/GeneralProvider";
import {RosteringContext} from "./RosteringContextProvider";
import {contractTypeIdMapping} from "../../users/UserGql";
import {getDevelopmentEnv} from "../../../../routes/NavMenu";

export interface StaffStationSkill {
    stationId: string,
    overlapScore: number
}

export interface StaffOption {
    identityId: string;
    employeeId: string;
    firstName: string;
    lastName: string;
    kanjiFirstName: string;
    kanjiLastName: string;
    stationSkillScore: StaffStationSkill[];
    totalNumberOfShifts: number;
    foreigner: boolean;
    onSchoolHoliday: boolean;
    maxHoursPerWeek: number;
    workHoursInTheWeek: number;
}

interface StaffContextInterface {
    getStaffList: (
        stationId: string,
        positionId: string,
        startTime: number,
        endTime: number,
        getMaxAvailableShiftLength: (employee: any) => number | undefined,
        selectedEmployee?: any
    ) => StaffSelectorItem[];
    allStaff: any;
}

export const autoFillKey = "auto-fill";
export const highSchoolerName = "HighSchoolStudent";

export const isEmployeeHighschooler = (employee) => {
    const modification = employee?.contractModification || employee?.contract?.contractModificationName;
    return modification === highSchoolerName;
}

export const isForeigner = (employee) => {
    return employee?.contract?.foreigner || employee?.foreigner;
}

export const isForeignStudent = (employee) => {
    return employee?.contract?.contractTypeId === contractTypeIdMapping.foreignStudentId || employee?.contractTypeId === contractTypeIdMapping.foreignStudentId;
}

export const isEmployeeLateWorker = (employee) => {
    return employee?.contract?.lateWorker || employee?.lateWorker;
}

export const StaffListContext = React.createContext<StaffContextInterface | null>(null);

interface StaffContextProviderProps {
    staff: StaffOption[];
    stations: any[];
    children: any;
}

export const StaffListProvider = (props: StaffContextProviderProps) => {
    const lang = useContext(GeneralContext);
    const rosteringContext = useContext(RosteringContext);
    const createStaffListItem = (employee: any, stationId?: string) => {
        let listItem;
        let sortKey = employee.lastName + " " + employee.firstName;
        if (stationId && employee.stationSkillScore && employee.stationSkillScore.length > 0) {
            const skill = employee.stationSkillScore.find(skillScore => skillScore.stationId === stationId);
            if (skill) {
                sortKey = skill.score;
            }
        }

        let contractTag: string | undefined = undefined;
        if (getDevelopmentEnv() !== "prod") {
            const contractTypeId = employee?.contract?.contractTypeId ?? employee?.contractTypeId;
            const lateWorker = employee?.contract?.lateWorker ?? employee?.lateWorker;
            const contractModification = employee?.contract?.contractModificationName ?? employee?.contractModification;
            const highSchooler = contractModification === highSchoolerName;
            const foreigner = employee?.contract?.foreigner ?? employee?.foreigner;
            if (foreigner) {
                if (contractTypeId === contractTypeIdMapping.foreignWorkerId) {
                    if (lateWorker === true) {
                        contractTag = "FL"; // Late worker
                    } else {
                        contractTag = "FW"; // Worker
                    }
                } else if (contractTypeId === contractTypeIdMapping.foreignStudentId){
                    if (highSchooler) {
                        contractTag = "FH"; //High schooler
                    } else {
                        contractTag = "FS" //Student
                    }
                }
            } else {
                if (highSchooler) {
                    contractTag = "高";
                } else {
                    if (contractTypeId === contractTypeIdMapping.fullTimeId) {
                        contractTag = "社";
                    } else if (lateWorker === true) {
                        contractTag = "ラ";
                    } else if (contractTypeId === contractTypeIdMapping.partTimeId) {
                        contractTag = "パ";
                    } else if (contractTypeId === contractTypeIdMapping.regularId) {
                        contractTag = "レ";
                    } else if (contractTypeId === contractTypeIdMapping.casualId) {
                        contractTag = "カ";
                    } else {
                        contractTag = "?";
                    }
                }
            }
        }

        listItem = {
            key: employee.identityId,
            value: getStaffName(employee, lang.language),
            sortKey: sortKey,
            contractTag: contractTag, // for debug only
        };

        return listItem;
    };

    const getStaffList = (
        stationId: string,
        positionId: string,
        startTime: number,
        endTime: number,
        getMaxAvailableShiftLength: (employee: any) => number | undefined,
        selectedEmployee?: any,
    ) => {
        const workLimitFilter = (employee) => {
            if (rosteringContext?.isTemplate) {
                return true;
            }

            if (!employee.availability) {
                return false;
            }

            const shiftLength = endTime - startTime;

            const maxShiftLength = getMaxAvailableShiftLength(employee);
            if (maxShiftLength !== undefined && shiftLength > maxShiftLength) {
                return false;
            }

            const employeeContract = lang.contractTypes?.find(contract => contract.id === employee.contractTypeId);

            /* This code was disabled after we find it difficult to edit double-shifts. Rules can be broken while editing

            if (rosteringContext && isEmployeeHighschooler(employee)) {
                let assignedNetShiftLength = 0;
                const myPositions = rosteringContext.getMyPositions(employee.identityId);
                myPositions?.forEach(p => {
                    let breakLength = 0;
                    p.breaks?.forEach((brek) => breakLength += (brek.end - brek.start));
                    assignedNetShiftLength += (p.end - p.start - breakLength)
                });
                if (shiftLength + assignedNetShiftLength > employeeContract.maxHoursPerDay * 3600) {
                    return false;
                }
            }
             */

            if (!isEmployeeLateWorker(employee) && endTime > employeeContract.latestEndTime) {
                return false;
            }

            return !(employee.availability.start > startTime || employee.availability.end < endTime);
        };

        const alreadyAssignedFilter = (employee) => {
            //For a given employee, walk through all positions and return false if
            //they are already assigned to a position which overlaps this one.

            let alreadyAssigned = false;
            props.stations.forEach((station) => {
                station.positions.forEach((position) => {
                    if (position.employee && position.employee.identityId === employee.identityId) {
                        if (!(position.start >= endTime || position.end <= startTime)) {
                            alreadyAssigned = true;
                        }
                    }
                });
            });

            return !alreadyAssigned;
        }

        const staffOptions = props.staff.filter(workLimitFilter).filter(alreadyAssignedFilter);

        let availableStaff = staffOptions.map(employee => createStaffListItem(employee, stationId));
        if(!rosteringContext?.isTemplate) {
            availableStaff = availableStaff.filter((staff) => staff.sortKey > 0);
        }
        availableStaff.sort(function (a, b) {
            let sortValue = 0;
            if (a.sortKey < b.sortKey) {
                sortValue = -1;
            } else if (a.sortKey > b.sortKey) {
                sortValue = 1;
            }

            if (typeof a.sortKey === "number" && typeof b.sortKey === "number") {
                // For numbers, we want descending order.
                sortValue = sortValue * -1;
            }

            return sortValue;
        });

        if (selectedEmployee) {
            const employeeInList = availableStaff.find(employee => employee.key === selectedEmployee.identityId);
            if (!employeeInList) {
                const identity = props.staff.find(s => s.identityId === selectedEmployee.identityId);
                availableStaff.unshift(createStaffListItem(identity ? {
                    ...selectedEmployee,
                    fistName: identity.firstName,
                    lastName: identity.lastName,
                    kanjiFirstName: identity.kanjiFirstName,
                    kanjiLastName: identity.kanjiLastName,
                } : selectedEmployee));
            }
        }

        /*
         * Check if anyone in the list has been assigned to multiple shifts,
         * and alter the displayed value.
         */
        availableStaff.forEach(staff => {
            staff.totalNumberOfShifts = 0;
            staff.shiftIndex = 0;
            props.stations.forEach(station => {
                station.positions.forEach(position => {
                    if (position.employee && position.employee.identityId === staff.key) {
                        // Count the total number of positions assigned.
                        staff.totalNumberOfShifts++;

                        if (position.id === positionId) {
                            // If this is the shift we're building a dropdown for,
                            // make a note of which shift it was. This will be displayed
                            // next to the user's name.
                            staff.shiftIndex = staff.totalNumberOfShifts;
                        }
                    }
                })
            });

            if (!staff.shiftIndex && staff.totalNumberOfShifts > 0) {
                // If the employee is assigned to other shifts than this one,
                // then set the shift index to indicate that the user would
                // be increasing the number of shifts.
                staff.shiftIndex = staff.totalNumberOfShifts + 1;
            }

            if (staff.shiftIndex > 0 && !(staff.shiftIndex === 1 && staff.totalNumberOfShifts === 1)) {
                staff.value = `${staff.value} (${staff.shiftIndex})`
            }
        });

        const autoFillItem = {key: autoFillKey, value: lang.strings.rosteringEditAutoFill};
        availableStaff.unshift(autoFillItem);

        return availableStaff;
    }

    const staffContextContainer = {
        getStaffList: getStaffList,
        allStaff: props.staff
    };

    return (
        <StaffListContext.Provider value={staffContextContainer}>
            {props.children}
        </StaffListContext.Provider>
    )
}