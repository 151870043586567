import {IDENTITY_CONFIG, METADATA_OIDC} from "../utils/Const";
import {Log, ILogger, UserManager, WebStorageStateStore} from "oidc-client-ts";
import config from "../../config";
import {aiLog, Level} from "../providers/AppInsights";
import { jwtDecode }  from 'jwt-decode';

const aiLogger: ILogger = {
    error: (message?: any, ...optionalParams: any[]) => {
        if (message instanceof String) {
            aiLog(Level.ERROR, `oidc-client|${message}`);
        }
        console.log(message, ...optionalParams);
    },
    info: (message?: any, ...optionalParams: any[]) => {
        if (message instanceof String) {
            aiLog(Level.INFO, `oidc-client|${message}`);
        }
        console.log(message, ...optionalParams);
    },
    debug: (message?: any, ...optionalParams: any[]) => {
        if (message instanceof String) {
            aiLog(Level.DEBUG, `oidc-client|${message}`);
        }
        console.log(message, ...optionalParams);
    },
    warn: (message?: any, ...optionalParams: any[]) => {
        if (message instanceof String) {
            aiLog(Level.WARNING, `oidc-client|${message}`);
        }
        console.log(message, ...optionalParams);
    }
}

export default class AuthService {
    UserManager;

    constructor() {
        // Logger
        Log.setLogger(aiLogger);
        Log.setLevel(Log.ERROR);

        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata: {
                ...METADATA_OIDC
            }
        });
        
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });

        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().catch(e => {
            aiLog(Level.DEBUG, `signinRedirectCallback failed. Navigate to the root to start login flow again`);
            window.location.replace("/");
        }).then(() => {});
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

    getUserName = (key: string) => {
        const oidcStorage = sessionStorage.getItem(`oidc.user:${config.REACT_APP_AUTH_URL}:${config.REACT_APP_IDENTITY_CLIENT_ID}`);
        if (oidcStorage != null) {
            const oidcJson = JSON.parse(oidcStorage);
            const decoded: any = jwtDecode(oidcJson.access_token);
            return decoded[key];
        }
        return;
    };

    getUserRole = () => {
        const oidcStorage = sessionStorage.getItem(`oidc.user:${config.REACT_APP_AUTH_URL}:${config.REACT_APP_IDENTITY_CLIENT_ID}`);
        if (oidcStorage != null) {
            const oidcJson = JSON.parse(oidcStorage);
            const decoded: any = jwtDecode(oidcJson.access_token);
            return decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        }
        return;
    };

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };

    signoutRedirect = () => {
        this.UserManager.signoutRedirect({});
    };

    navigateToScreen = () => {
        window.location.replace("/");
    };

    isAuthenticated = () => {
        const oidcStorage = sessionStorage.getItem(`oidc.user:${config.REACT_APP_AUTH_URL}:${config.REACT_APP_IDENTITY_CLIENT_ID}`);
         
        if (!oidcStorage) {
            return false;
        }

        const oidcJson = JSON.parse(oidcStorage);
        return (!!oidcJson && !!oidcJson.access_token)
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    signoutSilent = () => {
        this.UserManager.signoutSilent();
    };

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = async () => {
        if (!this.isAuthenticated()) { // The Logout page can be reached by browser's back button,
            window.location.replace("/");
        }
        const user = await this.UserManager.getUser();
        const id_token = user.id_token;
        this.UserManager.signoutRedirect({
            id_token_hint: id_token
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
//            localStorage.clear();
            window.location.href = "/";
        });
        this.UserManager.clearStaleState();
    };

    getAuthToken = () => {
        const oidcStorage = sessionStorage.getItem(`oidc.user:${config.REACT_APP_AUTH_URL}:${config.REACT_APP_IDENTITY_CLIENT_ID}`);
        
        if (!oidcStorage) {
            return null;
        }
        const oidcJson = JSON.parse(oidcStorage);

        return oidcJson ? oidcJson.access_token : null;
    }
}
