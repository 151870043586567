import config from "../../config";

let host = window.location.protocol.concat("//");
host = host.concat(window.location.hostname);
if (window.location.port) {
    host = host.concat(":");
    host = host.concat(window.location.port);
}

export const AUTH_SERVER_URL = config.REACT_APP_AUTH_URL || "";
export const AUTH_API_URL = AUTH_SERVER_URL.concat("/api");
export const AUTH_CREATE_URL = AUTH_API_URL.concat("/accounts");

export const IDENTITY_CONFIG = {
    authority: config.REACT_APP_AUTH_URL,
    client_id: config.REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: host.concat(config.REACT_APP_REDIRECT_URL || ""),
    post_logout_redirect_uri: host.concat(config.REACT_APP_LOGOFF_REDIRECT_URL || ""),
    response_type: "code",
    scope: "openid profile email api.read offline_access IdentityServerApi",
    filterProtocolClaims: true,
    loadUserInfo: true,
};

let authUrl = config.REACT_APP_AUTH_URL || "";

export const METADATA_OIDC = {
    issuer: authUrl,
    jwks_uri: authUrl.concat("/.well-known/openid-configuration/jwks"),
    authorization_endpoint: authUrl.concat("/connect/authorize"),
    token_endpoint: authUrl.concat("/connect/token"),
    userinfo_endpoint: authUrl.concat("/connect/userinfo"),
    end_session_endpoint: authUrl.concat("/connect/endsession"),

    /* TODO There is a huge reload loop issue with using this functionality on cookie
    protected browsers (safari, firefox) and modes (chrome incognito)
    check_session_iframe: authUrl.concat('/connect/checksession'),
    */

    revocation_endpoint: authUrl.concat("/connect/revocation"),
    introspection_endpoint: authUrl.concat("/connect/introspect")
};

export const ROLES = {
    Admin: 'Admin',
    User: 'User'
};

