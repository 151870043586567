import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import config from "../../config";

let appInsights: ApplicationInsights;
let reactPlugin;

export const startAppInsights = () => {
    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: config.APP_INSIGHTS_INSTRUMENTATION_KEY,
            extensions: [reactPlugin]
        }
    });
    appInsights.loadAppInsights();
};


export enum Level {
    ERROR = "ERROR",
    WARNING = "WARNING",
    INFO = "INFO",
    DEBUG = "DEBUG"
}

export const aiLog = (level: Level, message: string) => {
    appInsights.trackEvent({name: `${config.APP_INSIGHTS_NAME}|${level}|${message}`});
}

