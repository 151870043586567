import {gql, useQuery} from "@apollo/client";
import {debugError} from "../utils/Debug";
import {convertDateToGqlDate, convertGqlDateToDate} from "../utils/DateHelper";
import {useContext, useState} from "react";
import {AuthContext} from "../providers/AuthProvider";

export const GET_HOLIDAYS = gql`
    query GetHolidays ($param: countryCodeAndDateRange!) {
        holidays(holidays:$param) {
            holidays {
                name
                date
            }
        }
    }
`;

export const useHolidays = () => {
    const auth = useContext(AuthContext);
    const today = new Date();
    const yearEarlier = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    const yearLater = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    const gqlStartDate = convertDateToGqlDate(yearEarlier);
    const gqlEndDate = convertDateToGqlDate(yearLater);
    const [holidays, setHolidays] = useState<Date[]>(new Array<Date>());

    useQuery(
        GET_HOLIDAYS,
        {
            skip: !auth.isAuthenticated(),
            variables: {
                param: {
                    "start": gqlStartDate,
                    "end": gqlEndDate
                }
            },
            onCompleted: (data) => {
                const array: Date[] = [];
                data.holidays.holidays.forEach((holiday) => {
                    array.push(convertGqlDateToDate(holiday.date));
                });
                setHolidays(array);
            },
            onError: (error) => {
                debugError(`Get holidays: ${error.message}`);
            }
        }
    )

    return holidays;
}
