import React, {useContext} from 'react';
import {styled} from '@mui/material/styles';
import {Link, useLocation} from 'react-router-dom';
import {Avatar, Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import ExitToApp from '@mui/icons-material/ExitToApp';
import NoteAdd from '@mui/icons-material/NoteAdd';
import ViewList from '@mui/icons-material/ViewList';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import DateRange from '@mui/icons-material/DateRange';
import TrendingUp from '@mui/icons-material/TrendingUp';
import People from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {GeneralContext} from "../components/providers/GeneralProvider";
import {AuthContext} from "../components/providers/AuthProvider";
import StoreIcon from '@mui/icons-material/Store';
import {CanAccess, Permission} from '../components/utils/Permissions';
import {SettingsContext} from '../components/providers/SettingsProvider';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Tune from '@mui/icons-material/Tune';
import {Assignment, AssignmentInd, FitnessCenter, Repeat, Schedule} from "@mui/icons-material";
import {grey} from '@mui/material/colors';
import config from "../config";
import {ReactComponent as DevIcon} from "assets/logo.svg";

const PREFIX = 'NavMenu';

const classes = {
    root: `${PREFIX}-root`,
    userName: `${PREFIX}-userName`
};

const Root = styled('div')(({theme: Theme}) => ({
    [`& .${classes.root}`]: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    [`& .${classes.userName}`]: {
        paddingLeft: '1rem',
        paddingBottom: '0.5rem'
    },
}));

const linkItemStyle = (smallMenu: boolean) => {
    return {
        display: "flex",
        justifyContent: "center",
        lineHeight: '1.43',
        color: 'text.primary',
        '&:hover': {
            backgroundColor: 'action.hover'
        },
        '> .MuiListItemIcon-root': {
            display: smallMenu ? "flex" : "block",
            justifyContent: "inherit"
        },
        '&.Mui-selected': {
            color: 'primary.main',
            '> .MuiListItemIcon-root': {
                color: 'primary.main'
            }
        }
    };
}

const smallMenuStyle = {
    display: "flex",
    justifyContent: "center",
};

const linkItemTextStyle = {
    fontSize: '14px',
    fontWeight: 'bold'
};

export const getDevelopmentEnv = () => {
    const clientId = config.REACT_APP_IDENTITY_CLIENT_ID;
    let developmentEnv = "prod";
    switch (clientId) {
        case "kurasushi_local":
            developmentEnv = "local";
            break;
        case "kurasushi_dev_app_private":
        case "kurasushi_dev_app_public":
        case "kurasushi_dev_private":
        case "kurasushi_dev_public":
            developmentEnv = "dev";
            break;
        case "kurasushi_stag_private":
        case "kurasushi_stag_public":
            developmentEnv = "stag";
            break;
    }
    return developmentEnv;
}

export const NavMenu = (props: any) => {
    const location = useLocation();
    const generalContext = useContext(GeneralContext);
    const auth = useContext(AuthContext);
    const settings = useContext(SettingsContext);
    const userRole = generalContext.userRole;
    let userName;
    let initials;
    const smallMenu=settings.smallMode;
    const setSmallMenu = settings.setSmallMode;

    if (generalContext.language === "ja") {
        userName = auth.getUserName("name#ja-Hani-JP").toString().trim();
        // if the user doesn't have Kanji name, use the other name instead.
        if (userName.length === 0) {
            userName = auth.getUserName("name").toString();
        }
        initials = userName.substring(0, 1); // Use the first character
    } else {
        userName = auth.getUserName("name").toString();
        initials = userName.split(" ").map((n) => n[0]).join("");
    }

    const fulltimeStaff = generalContext.isFullTimeStaff();

    const mainLinks = [
        {
            link: "/schedules",
            text: generalContext.strings.navDrawerCalendar,
            icon: <DateRange/>,
            permission: Permission.ViewSchedules
        },
        {
            link: "/rosteredStaffLocationSelector",
            text: generalContext.strings.navDrawerRosteredStaff,
            icon: <ViewList/>,
            permission: Permission.ViewRosteredStaffLocationSelectorMenuItem
        },
        {
            link: "/rosteredStaff",
            text: generalContext.strings.navDrawerRosteredStaff,
            icon: <ViewList/>,
            permission: Permission.ViewRosteredStaffMenuItem
        },
        {
            link: "/dailyDuties",
            text: generalContext.strings.navDrawerDailyDuties,
            icon: <AssignmentInd/>,
            permission: Permission.ViewDailyDutiesMenuItem
        },
        {
            link: "/templates",
            text: generalContext.strings.navDrawerCreateSchedule,
            icon: <NoteAdd/>,
            permission: Permission.ViewTemplates
        },
        {
            link: "/optimise",
            text: generalContext.strings.navDrawerBatchOptimise,
            icon: <Repeat/>,
            permission: Permission.ViewBatchOptimisation
        },
        {
            link: "/users",
            text: generalContext.strings.navDrawerUsers,
            icon: <People/>,
            permission: Permission.ViewUsers
        },
        {
            link: "/staff",
            text: generalContext.strings.navDrawerStaff,
            icon: <People/>,
            permission: Permission.ViewStaff
        },
        {
            link: "/restaurants",
            text: generalContext.strings.navDrawerRestaurants,
            icon: <StoreIcon/>,
            permission: Permission.ViewRestaurants
        },
        {
            link: "/targets",
            text: generalContext.strings.targetsPageTitle,
            icon: <TrendingUp/>,
            permission: Permission.ViewTargets
        },
        {
            link: "/shifts",
            text: generalContext.strings.navDrawerShifts,
            icon: <DateRange/>,
            permission: Permission.ViewShifts
        },
        {
            link: "/availability",
            text: fulltimeStaff ? generalContext.strings.staffTimeOffTab : generalContext.strings.staffAvailability,
            icon: <EventIcon/>,
            permission: Permission.viewStaffAvailability
        },
        {
            link: "/fixedpositions",
            text: generalContext.strings.fixedPositions,
            icon: <AssignmentIcon/>,
            permission: Permission.ViewFixedPositions
        },
        {
            link: "/overtime",
            text: generalContext.strings.overtime,
            icon: <HourglassEmptyIcon/>,
            permission: Permission.ViewMonthlyOvertime
        },
        {
            link: "/availabilityReview",
            text: generalContext.strings.navDrawerAvailability,
            icon: <EventAvailableIcon/>,
            permission: Permission.ViewAvailabilityReview
        },
        {
            link: "/training",
            text: generalContext.strings.navDrawerTraining,
            icon: <FitnessCenter/>,
            permission: Permission.ViewTraining
        },
        {
            link: "/trainingSetup",
            text: generalContext.strings.navDrawerTrainingSetup,
            icon: <Assignment/>,
            permission: Permission.ViewTrainingSetup
        }
    ];

    if (generalContext.userData?.myEmployee.isRosterable) {
        mainLinks.push({
            link: "/timeOff",
            text: generalContext.strings.staffTimeOffTab,
            icon: <Schedule/>,
            permission: Permission.ViewTimeOff
        });
    }

    const settingsLinks = [
        {
            link: "/deadlinesettings",
            text: generalContext.strings.navDrawerDeadlineSetup,
            icon: <Tune/>,
            permission: Permission.ViewDeadlineSetup
        },
        {
            link: "/accountsettings",
            text: generalContext.strings.navDrawerUserAccount,
            icon: <AccountCircle/>
        },
        {
            link: "/systemsettings",
            text: generalContext.strings.navDrawerSystemSettings,
            icon: <BusinessIcon/>,
            permission: Permission.ViewSystemSettings
        },
        {
            link: "/restaurantsettings",
            text: generalContext.strings.navDrawerRestaurant,
            icon: <StoreIcon/>,
            permission: Permission.ViewRestaurantsSettings
        },
    ];

    const AllowLink = (permission?: Permission) => {
        if (!!permission) {
            return CanAccess(permission, userRole);
        }

        return true;
    };

    const validMainLinks = mainLinks.filter((linkObject) => {
            return AllowLink(linkObject.permission);
        }
    );

    const validSettingLinks = settingsLinks.filter((linkObject) => {
            return AllowLink(linkObject.permission);
        }
    );

    const handleSmallMenu = () => {
        setSmallMenu(!smallMenu);
    };

    const menuStyle = smallMenu ? smallMenuStyle : {};

    const checkSelected = (link) => {
        if (location.pathname === "/") {
            return link === "/schedules" || link === "/shifts";
        } else {
            return link === location.pathname;
        }
    }

    const buildId = (link) => {
        return `${link.substring(1)}-link`;
    }

    let avatar = <Avatar sx={{
        marginLeft: smallMenu ? 0 : '1rem',
        marginTop: '3rem',
        marginBottom: '1rem',
        width: '4rem',
        height: '4rem',
        colour: 'primary.main',
        backgroundColor: 'primary.main'}}>{initials}</Avatar>;

    const developmentEnv= getDevelopmentEnv();
    if (developmentEnv !== "prod") {
        avatar = <Avatar variant="square" sx={{
            marginLeft: smallMenu ? 0 : '1rem',
            marginTop: '3rem',
            marginBottom: '1rem',
            width: '4rem',
            height: '4rem',
            backgroundColor: 'black'}}>
            <div >
                <DevIcon style={{height: '40px', marginBottom: '-3px'}} />
                <div style={{textAlign: 'center'}}>{developmentEnv}</div>
            </div>
        </Avatar>;
    }

    return (
        <Root>
            {!props.hideMenu && (
                <Box id="menuToggle" onClick={handleSmallMenu} sx={{
                    position: "absolute",
                    right: "0",
                    padding: "0.5rem",
                    color: grey[700]
                }}>
                    {smallMenu ? <ChevronRight fontSize="large" htmlColor="black"/> : <ChevronLeft fontSize="large" htmlColor="black"/>}
                </Box>
            )}
            <Box sx={menuStyle}>
                {avatar}
            </Box>
            {!smallMenu && (<Typography className={classes.userName} variant={"h6"}>{userName}</Typography>)}
            <Divider/>
            <List>
                {
                    validMainLinks.map((linkObject, index) => (
                        <Link
                            to={linkObject.link}
                            id={buildId(linkObject.link)}
                            key={index}
                            onClick={props.hideMenu}
                        >
                            <ListItemButton
                                key={index}
                                selected={checkSelected(linkObject.link)}
                                sx={linkItemStyle(smallMenu)}
                            >
                                <ListItemIcon>{linkObject.icon}</ListItemIcon>
                                {!smallMenu && (
                                    <ListItemText disableTypography primary={linkObject.text} sx={linkItemTextStyle}/>)}
                            </ListItemButton>
                        </Link>
                    ))}
            </List>
            <Divider/>
            <div style={smallMenu ? {display: "none"} : {}}>
                <Box sx={{margin: '15px 0 0 10px', fontSize: '14px', color: 'text.secondary'}}>
                    {generalContext.strings.navDrawerSettings}
                </Box>
            </div>
            <List>
                {validSettingLinks.map((linkObject, index) => (
                    <Link
                        to={linkObject.link}
                        id={buildId(linkObject.link)}
                        key={index}
                        onClick={props.hideMenu}
                    >
                        <ListItemButton
                            key={index}
                            selected={checkSelected(linkObject.link)}
                            sx={linkItemStyle(smallMenu)}
                        >
                            <ListItemIcon>{linkObject.icon}</ListItemIcon>
                            {!smallMenu && (
                                <ListItemText disableTypography primary={linkObject.text} sx={linkItemTextStyle}/>)}
                        </ListItemButton>
                    </Link>
                ))}
            </List>
            <Divider/>
            <Link
                to='/logout'
                onClick={props.hideMenu}
            >
                <ListItemButton
                    selected={'/logout' === location.pathname}
                    sx={[linkItemStyle(smallMenu), {marginTop: '10px'}]}
                >
                    <ListItemIcon><ExitToApp/></ListItemIcon>
                    {!smallMenu && (<ListItemText disableTypography
                                                  primary={generalContext.strings.navDrawerLogout}
                                                  sx={linkItemTextStyle}/>)}
                </ListItemButton>
            </Link>
        </Root>
    );
}
