import config from "../../config.js";

export const debugError = (message: string) => {
    if (config.DEBUG_MODE) {
        console.log(`ERROR: ${message}`);
    }
}

export const debugLog = (message: string) => {
    if (config.DEBUG_MODE) {
        console.log(`LOG: ${message}`);
    }
}