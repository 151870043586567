export function seconds2Hour(x) {
    return Math.floor(x/3600);
}

export function seconds2MinsPastHour(x) {
    return (x%3600)/60;
}

export function dateToSeconds(x: Date, lateMidnight?: boolean) {
    let seconds = x.getHours() * 3600 + x.getMinutes() * 60;
    if (lateMidnight && seconds === 0) {
        seconds = 24 * 60 * 60;
    }

    return seconds;
}

export function dateToSecondsLate(x: Date) {
    return dateToSeconds(x, true);
}

export function timeToSeconds(x: string) {
    const xArray=x.split(":")
    
    return Number(xArray[0])*3600 + Number(xArray[1])*60
}

export const appendOrdinal = (number: number) => {
    if (number > 3 && number < 21) return number+'th';
    switch (number % 10) {
      case 1:  return number+"st";
      case 2:  return number+"nd";
      case 3:  return number+"rd";
      default: return number+"th";
    }
}

export const secondsToDate = (x: number) => {
    const date = new Date(0);
    date.setHours(
        seconds2Hour(x),
        seconds2MinsPastHour(x)
    );
    return date;
}

export function secondsToTimeString(seconds: number) {
    const date = secondsToDate(seconds);
    let hours = date.getHours().toString();
    if (hours.length === 1) {
        hours = "0" + hours;
    }

    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) {
        minutes = "0" + minutes;
    }

    return hours + ":" + minutes;
}

export function secondsToHours(seconds: number) {
    return (Math.round((seconds / 3600) * 10) / 10) //1dp
};

export const displaySecondsAsTimePeriod = (startSeconds: number, endSeconds: number) => {
    return `${secondsToTimeString(startSeconds)} - ${secondsToTimeString(endSeconds)}`;
}

export const displaySecondsAsTimePeriodShort = (startSeconds: number, endSeconds: number) => {
    return `${secondsToTimeString(startSeconds)}-${secondsToTimeString(endSeconds)}`;
}