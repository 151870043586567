//SETTINGS PAGE
import {gql} from "@apollo/client";

export const GET_SETTINGS_LANGUAGE_QUERY = gql`
    query GetSettingsLanguage {
        mySettings {
            displayLanguage
        }
    }
`;

export const CREATE_SETTINGS_LANGUAGE = gql`
    mutation CreateSettingsLanguage($settings: createSettings!) {
        createMySettings(settings: $settings) {
            displayLanguage
        }
    }
`;

export const SET_SETTINGS_LANGUAGE = gql`
    mutation CreateSettingsLanguage($settings: updateSettings!) {
        updateMySettings(settings: $settings) {
            displayLanguage
        }
    }
`;

export const GET_LANGUAGE_OPTIONS = gql`
    query GetLanguageOptions {
        languages {
            name
            nativeName
        }
    }
`;

export enum MODIFICATION_IDS {
    NONE = 0,
    HIGH = 1, // High school student
    REGULAR = 2
}

// WORK RULES
export const GET_CONTRACT_TYPES_QUERY = gql`
    query GetContractTypesQuery {
        contractTypeAndOvertimeRules {
            contractTypes {
                earliestStartTime
                id
                latestEndTime
                maxConsecutiveDays
                maxDaysPerWeek
                maxHoursPerDay
                maxHoursPerWeek
                minHoursPerDay
                minHoursPerWeek
                overtimeAllowed
                workRuleName
                workRuleType
            }
            overTimeRules {
                maxHoursPerMonth
                allowedOccurences
            }
            contractModifications {
                id
                name
            }
        }
    }
`;

//BREAK RULES
export const GET_BREAK_RULES_QUERY = gql`
    query GetBreakRulesQuery {
        workHoursRestrictions {
            breakRules {
                id
                minHours
                maxHours
                breakLength
                canSplitBreak
            }
            organisationSettings {
                minMinutesAfterBreak
                minMinutesBeforeBreak
                shortestBreak
            }
        }
    }
`;

// AVAILABILITY PERIOD
export const CREATE_AVAILABILITY_PERIOD_MUTATION = gql`
    mutation CreateAvailabilityPeriod($availabilityPeriod: createAvailabilityPeriod!) {
      createAvailabilityPeriod(availabilityPeriod: $availabilityPeriod) {
        id
        start
        end
        amendmentStart
        amendmentEnd
      }
    }
`;

export const UPDATE_AVAILABILITY_PERIOD_MUTATION = gql`
    mutation UpdateAvailabilityPeriod($availabilityPeriod: updateAvailabilityPeriod!) {
      updateAvailabilityPeriod(availabilityPeriod: $availabilityPeriod) {
        id
        start
        end
        amendmentStart
        amendmentEnd
      }
    }
`;

export const GET_AVAILABILITY_PERIODS_QUERY = gql`
    query GetAvailabilityPeriods {
      allAvailabilityPeriods {
        id
        start
        end
      }
    }
`;

export const GET_CURRENT_AND_FUTURE_AVAILABILITY_PERIODS_QUERY = gql`
    query GetCurrentAndFutureAvailabilityPeriods {
      currentAndFutureAvailabilityPeriods {
        id
        start
        end
        amendmentStart
        amendmentEnd
      }
    }
`;

export const GET_CURRENT_AND_AMENDABLE_AVAILABILITY_PERIODS_QUERY = gql`
    query GetCurrentAndAmendableAvailabilityPeriods {
      currentAndAmendableAvailabilityPeriods {
        id
        start
        end
        amendmentStart
        amendmentEnd
      }
    }
`;

export const DELETE_AVAILABILITY_PERIOD_MUTATION = gql`
    mutation DeleteAvailabilityPeriod($id: Int!) {
      deleteAvailabilityPeriod(id: $id) 
    }
`;

// AVAILABILITY EXCEPTION PERIOD
export const GET_CURRENT_AND_FUTURE_AVAILABILITY_EXCEPTION_PERIODS_QUERY = gql`
    query GetCurrentAndFutureAvailabilityExceptionPeriods {
      currentAndFutureAvailabilityExceptionPeriods {
        id
        start
        end
        deadline
      }
    }
`;

export const GET_COMING_AVAILABILITY_EXCEPTION_PERIOD_QUERY = gql`
    query GetComingAvailabilityExceptionPeriod {
      comingAvailabilityExceptionPeriod {
        id
        start
        end
        deadline
      }
    }
`;

export const CREATE_AVAILABILITY_EXCEPTION_PERIOD_MUTATION = gql`
    mutation CreateAvailabilityExceptionPeriod($availabilityExceptionPeriod: createAvailabilityExceptionPeriod!) {
      createAvailabilityExceptionPeriod(availabilityExceptionPeriod: $availabilityExceptionPeriod) {
        id
        start
        end
        deadline
      }
    }
`;

export const GET_AVAILABILITY_EXCEPTION_PERIOD_QUERY = gql`
    query getAvailabilityExceptionPeriod($id: Guid!) {
      availabilityExceptionPeriod(id: $id) {
        id
        start
        end
        deadline
      }
    }
`;

export const UPDATE_AVAILABILITY_EXCEPTION_PERIOD_MUTATION = gql`
    mutation UpdateAvailabilityExceptionPeriod($availabilityExceptionPeriod: updateAvailabilityExceptionPeriod!) {
      updateAvailabilityExceptionPeriod(availabilityExceptionPeriod: $availabilityExceptionPeriod) {
        id
        start
        end
        deadline
      }
    }
`;

export const DELETE_AVAILABILITY_EXCEPTION_PERIOD_MUTATION = gql`
    mutation deleteAvailabilityExceptionPeriod($id: Guid!) {
      deleteAvailabilityExceptionPeriod(id: $id)
    }
`;

// OPERATING HOURS
export const UPDATE_OPERATING_HOURS_MUTATION = gql`
    mutation updateOperatingHours($operatingHours: updateOrgOperatingHours!) {
        setOrganisationOperatingHours(operatingHours: $operatingHours) {
            id
            startTime
            endTime
            openTime
            closeTime
        }
    }
`;

export const GET_OPERATING_HOURS_QUERY = gql`
    query getOperatingHours {
        organisationOperatingHours {
            operatingHours {
                startTime
                endTime
                openTime
                closeTime
                dayNumber
            }
        }
    }
`;

export const UPDATE_LOCATION_OPERATING_HOURS_MUTATION = gql`
    mutation updateLocationOperatingHours($operatingHours: updateLocationOperatingHours!) {
        setLocationOperatingHours(operatingHours: $operatingHours) {
            locationId
            startTime
            endTime
            openTime
            closeTime
            dayNumber
        }
    }
`;

export const GET_LOCATION_OPERATING_HOURS_QUERY = gql`
  query getLocationOperatingHours($locationId: Int!) {
        locationOperatingHours(locationId: $locationId) {
        operatingHours {
            startTime
            endTime
            openTime
            closeTime
            dayNumber
            }
        }
    }
`;