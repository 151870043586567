import React, { useContext } from "react";
import {ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, split} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { AuthContext } from "./AuthProvider";
import config from "../../config";
import {WebSocketLink} from "@apollo/client/link/ws";
import {getMainDefinition} from "@apollo/client/utilities";


export const ApolloProviderWrapper = (props) => {
    const auth = useContext(AuthContext);

    const httpLink = createHttpLink({
        uri: `${config.REACT_APP_API_URL}`,
      });
    
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = auth.getAuthToken();
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
            "tenant-id": config.TENANT_ID,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        authLink.concat(new WebSocketLink({
            uri: `${config.OPTIMIZATION_WEB_SOCKET_URL}`,
            options: {
                reconnect: true,
                connectionParams: {
                    "tenant-id": config.TENANT_ID,
                    Authorization: `Bearer ${auth.getAuthToken()}`,
                },
            }
        })),
        authLink.concat(httpLink),
    );
    
    const client = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache({
        addTypename: false
      })
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
