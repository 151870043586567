import {gql} from "@apollo/client";
import { useQuery } from "@apollo/client";

// RESTAURANTS PAGE
export const GET_RESTAURANTS_QUERY = gql`
    query GetRestaurants {
        locations {
            id
            name
            locationNumber
        }
    }
`;

export const GET_RESTAURANTS_FOR_MANAGER_QUERY = gql`
    query GetRestaurants($managerId: Guid!) {
        locationsForManager(managerId: $managerId) {
            id
            name
            locationNumber
        }
    }
`;

export const GET_MANAGEMENT_HIERARCHY = gql`
    query GetManagementHierarchy {
        managementHierarchy {
            identityId
            firstName
            lastName
            kanjiFirstName
            kanjiLastName
            areaManagers {
                identityId
                firstName
                lastName
                kanjiFirstName
                kanjiLastName
                supervisors {
                    identityId
                    firstName
                    lastName
                    kanjiFirstName
                    kanjiLastName
                    stores {
                        id
                        identityId
                        locationNumber
                        name
                    }
                }
            }
        }
    }
`;

export const CREATE_RESTAURANT_MUTATION = gql`
    mutation CreateLocation($location: createLocation!) {
        createLocation(location: $location) {
            id
            name
        }
    }
`;

export const UPDATE_RESTAURANT_MUTATION = gql`
    mutation UpdateLocation($location: updateLocation!) {
        updateLocation(location: $location) {
            id
            name
        }
    }
`;

export const DELETE_RESTAURANT_MUTATION = gql`
    mutation DeleteLocation($id: Int!) {
        deleteLocation(id: $id) {
            id
            name
        }
    }
`;

const GET_LOCATION_QUERY = gql`
    query GetLocation($id: Int) {
       location(id: $id) {
           id
           name
           locationNumber
       }
   }
`;

export interface LocationDetails {
    id: number,
    name: string,
    locationNumber: number
}

export const useLocationDetails = (id: string) => {
    const {data, error, loading} = useQuery(
        GET_LOCATION_QUERY,
        {
            variables: {
                id: parseInt(id)
            }
        }
    );

    let locationDetails: LocationDetails | undefined = undefined;

    if (data) {
        locationDetails = data.location;
    }

    return {locationDetails, error, loading};
}