export const getStaffName = (employee: any, language: string) => {
    return language === 'ja' ? getStaffNameInJapanese(employee) : getStaffNameInEnglish(employee);
}

export const getStaffNameInJapanese = (employee: any) => {
    // kanji names are optional. If they are not set, use the other names.
    if (employee.kanjiLastName && employee.kanjiFirstName) {
        return employee.kanjiLastName + " " + employee.kanjiFirstName;
    } else {
        return employee.lastName + " " + employee.firstName;
    }
}

export const getStaffNameInEnglish = (employee: { firstName: string, lastName: string }) => {
    return `${employee.firstName} ${employee.lastName}`;
}

export const getInitials = (name: string, lang: any) => {
    let initials: string | undefined;
    if (lang.language === "ja") {
        initials = name.substring(0, 1);
    } else {
        initials = name.split(" ").map((n) => n[0]).join("");
    }
    return initials;
}

